body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

#root > div {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'head head' 'holder main';
}

.header-wrapper {
  grid-area: head;
}

.holder {
  grid-area: holder;
}

.full-table-content {
  padding: 2rem;
}

.main-content {
  grid-area: main;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.main-content > .navigation-area {
  position: relative;
}

.main-content > .table-content {
  max-width: 100%;
  padding: 0 20px;
  overflow: hidden;
}

/* TODO when lib is used */
/*.paper-left-actions {*/
/*  display: none!important;*/
/*}*/

.tabs-holder {
  width: 100% !important;
}

/* TODO when lib is used */

.transaction-status,
.transaction-type,
.maintenance-status,
.bet-result {
  height: 25px;
  padding: 3px 0;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
}

.tournament-status {
  width: 110px;
  height: 25px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
}

.transaction-status-pending,
.maintenance-status-pending,
.free-bet-pending,
.bet-pending,
.tournament-status-pending,
.jackpot-pending,
.gamble-pending {
  width: 110px;
  color: #ffca5f;
  border: solid 1px #ffca5f;
  background-color: rgba(255, 202, 95, 0.06);
}

.jackpot-pending,
.gamble-pending {
  width: auto;
}

.transaction-status-success,
.transaction-type-win,
.bet-win {
  width: 110px;
  color: #76a145;
  border: solid 1px #76a145;
  background-color: rgba(118, 161, 69, 0.06);
}

.unknown {
  width: 110px;
  color: rgba(60, 64, 67, 0.3);
  border: solid 1px rgba(60, 64, 67, 0.3);
  background-color: rgba(0, 0, 0, 0.06);
}

.transaction-status-failed,
.transaction-type-lose,
.maintenance-status-finished,
.free-bet-expired,
.bet-lose,
.tournament-status-finished {
  width: 110px;
  color: #ef5d8f;
  border: solid 1px #ef5d8f;
  background-color: rgba(239, 93, 143, 0.06);
}

.tournament-status-finishedAndInActive {
  border: solid 1px #9fa8da;
  color: #9fa8da;
  background-color: rgba(159, 168, 218, 0.06);
}

.free-bet-web-active,
.transaction-status-delayed {
  width: 110px;
  border: solid 1px #9fa8da;
  color: #9fa8da;
  background-color: rgba(159, 168, 218, 0.06);
}

.transaction-type-roll-back,
.maintenance-status-canceled,
.free-bet-canceled,
.bet-canceled,
.tournament-status-canceled {
  width: 110px;
  color: #f9a19a;
  border: solid 1px #f9a19a;
  background-color: rgba(249, 161, 154, 0.06);
}

.maintenance-status-in-process,
.free-bet-active,
.tournament-status-live {
  width: 110px;
  color: #76a145;
  border: solid 1px #76a145;
  background-color: rgba(118, 161, 69, 0.06);
}

.tournament-status-stopped {
  color: #c48f71;
  border: solid 1px #c48f71;
  background-color: rgba(196, 143, 113, 0.06);
}

.partner-status-trial,
.user-type-test {
  width: 110px;
  color: #b763c6;
  border: solid 1px #b763c6;
  background-color: rgba(183, 99, 198, 0.06);
}

.partner-status-live,
.user-type-real {
  width: 110px;
  color: #46904a;
  border: solid 1px #46904a;
  background-color: rgba(70, 144, 74, 0.06);
}

.blackjack-push,
.bet-playing {
  width: 110px;
  color: #1473e6;
  border: solid 1px #1473e6;
  background-color: rgba(84, 70, 144, 0.06);
}

.teams-report-logo {
  height: 26px;
}

.teams-report-uniform {
  height: 26px;
}

.date-table-row {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* partner settings*/


.switcher-span {
  margin-right: 3px;
}

.right-title {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  justify-content: flex-end;
  align-items: center;
}

.btn-ps-edit {
  border: solid 1px #000 !important;
  --size: 24px !important;
  min-width: 40px !important;
  margin-left: 3px;
  min-height: inherit !important;
}

.edit-date-picker {
  width: 300px;
}

.edit-modal .modal-body {
  width: 300px !important;
}

.hide-modal-padding .modal-body {
  padding: 0;
}

.edit-modal .modal-footer {
  justify-content: flex-end !important;
}

.right-title div span {
  font-weight: bold;
  font-size: 11px;
  margin: 0 2px;
}

/*end partner settings*/

.hyperlink-color {
  color: #378be2;
}

.margin-bottom {
  margin-bottom: 16px;
}

.fish-result-modal-table-wrapper {
  max-height: 540px;
  overflow: scroll;
}

.create-black-jack-modal-content > div:only-of-type {
  max-width: 1024px;
}

.ta-n-body.ta-header.pointer-events-none {
  pointer-events: initial;
}

.success-title {
  display: flex;
  grid-gap: 1rem;
  align-items: center;
}

.error-icon {
  color: #e64e48;
}

.success-icon {
  color: #2bc784;
}

.swiper-pagination-bullet {
  background-color: rgba(0, 0, 0, 0.1);
}

.swiper-pagination-bullet-active {
  background-color: rgba(0, 0, 0, 0.4);
}

.highcharts-container {
  width: auto !important;
}

.inactive-tag {
  pointer-events: none;
}

.no-padding .modal-body {
  padding: 0 !important;
}

.reset-password-modal-title .modal-head {
  min-height: 5rem;
}

.table-height-limit .table-holder {
  max-height: 55rem !important;
}

.table-without-header.table-without-footer.table-height-limit .table-holder {
  max-height: 47rem !important;
}

.chart-paper {
  overflow: hidden;
}

.dashboard-widget-border {
  border: 1px solid rgba(0, 0, 0, 0.16) !important;
}

.negative-amount > .text {
  color: #f00;
}

.chart-period-filter-tabs {
  display: none;
}

.overlay-holder {
  --header-height: 62px;
}

.info-tag .tag-c {
  height: 36px;
  padding: 16px;
}

.tag-for-status .tag-cc {
  width: 110px;
}

.gene-horizontal-list-container :last-child ul li:last-child {
  padding-left: 0;
}

.hide-tabs-header .tabs-head {
  display: none;
}

.tournament-personal-info {
  padding: 1.6rem;
}

.align-center {
  align-items: center;
}

.lobby-switcher {
  min-width: fit-content;
}

.lobby-form {
  width: 200px !important;
}

.lobby-key-value {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #777;
}

.lobby-key-value span {
  color: #000;
  font-weight: 600;
}

.flex {
  display: flex;
}

.flex-gap {
  display: flex;
  gap: 1rem;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.display-none {
  display: none !important;
}

.margin-1 {
  margin: 1rem;
}

.fit-content {
  width: fit-content !important;
}

.full-width {
  width: 100%;
}

.relative {
  position: relative;
}

.absolute-loader {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* CORE table full height */

.full-height-table {
  flex: auto;
  overflow: hidden;
  height: calc(100vh - 102px);
}
.full-height-table .table-holder {
  flex: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.full-height-table .table-holder .scroll-holder {
  display: flex;
  flex: auto;
  height: auto;
}
.full-height-table .table-holder .ta-cell {
  cursor: default;
}
.full-height-table .table-holder .ta-cell .ellipsis-text {
  cursor: text;
  width: auto;
  display: initial;
}

/* for dashboard table */
.full-height-table-dashboard {
  height: calc(100vh - 154px);
}

/* for predictor leaderboard table */
.full-height-table-predictor-leaderboard {
  height: calc(100vh - 158px);
}

/* CORE table full height */