.bo-nav {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  height: 60px;
  flex-shrink: 0;
  user-select: none;
}

.bo-nav li {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.nav-item {
  text-transform: capitalize;
  cursor: pointer;
  transition: 400ms color;
  display: flex;
  align-items: center;
  width: 100%;
  margin-inline-start: 35px;
}

.nav-item-active {
  color: #1473e6;
}

.nav-item-inactive {
  color: #636363;
}

.bo-submenu {
  width: 700px;
}

.dashboard-nav-item {
  color: white;
  font-weight: revert;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon_color {
  background: #3d3d5f;
  color: #3d3d5f;
}

/* START: remove nested routes from selected navigation */
.bc-navigation-menu_title-wrapper {
  opacity: 1 !important;
}

.bc-navigation-menu_title-wrapper:not(:first-child) {
  display: none !important;
}

.bc-navigation-menu_title-wrapper::after {
  display: none !important;
}
/* START: remove nested routes from selected navigation */