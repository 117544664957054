.openToggle {
    background-color: #ffffff;
    color: black;
}

.openToggle:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
}

.closedToggle {
    background-color: inherit;
    color: #ffffff;
}

.closedToggle:hover {
    background-color: #ffffff;
    color: black;
}

.create-button-styles {
    border: 1px solid #ffffff;
    border-radius: 10px;
    height: 36px;
    padding: 0 20px;
    font-weight: 400;
    box-sizing: border-box;
    width: 120px;
    transition: 0.5s;
}

.create-button-content {
    display: flex;
    align-items: center;
}

.create-button-content div:first-child {
    margin-right: 10px;
}

.create-icon-button {
    font-size: 16px;
    margin-right: 6px;
    font-weight: 400;
}

.create-button-wrapper span {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3c4043;
}

.create-buttons-wrapper button {
    margin: 10px auto 5px;
}
